import ActionNode = require("Everlaw/UI/ActionNode");
import { Arr } from "core";
import Base = require("Everlaw/Base");
import BaseSelect = require("Everlaw/UI/BaseSelect");
import Checkbox = require("Everlaw/UI/Checkbox");
import Dom = require("Everlaw/Dom");
import Tooltip = require("Everlaw/UI/Tooltip");
import { EVERCLASS, everClassProp } from "Everlaw/EverAttribute/EverClass";

class MultiSelect<T extends Base.Object>
    extends BaseSelect<T[], T>
    implements BaseSelect.Filtering<T[], T>
{
    initialSelected: T | T[];
    selectAllAndNoneButtons: boolean;
    silentSelectAllAndNone: boolean;
    unsorted: boolean;
    selectAllNode: ActionNode;
    selectNoneNode: ActionNode;
    selectAllNoneNode: HTMLElement;
    // a map of className to array of selected elements; otherwise null
    protected _selectedMap: { [className: string]: T[] };
    onSelectAll() {}
    onSelectNone() {}
    protected selectAllOnClick() {
        this.selectAll(this.silentSelectAllAndNone);
        this.onSelectAll();
    }
    protected selectNoneOnClick() {
        this.unselectAll(this.silentSelectAllAndNone);
        this.onSelectNone();
    }
    constructor(params: MultiSelect.Params<T>) {
        super({ dontShowSelectIcon: true, ...params });
        if (this.colorItems) {
            Dom.addClass(this.menu, "multiselect__menu-colored");
        }
        if (this.selectAllAndNoneButtons) {
            this.selectAllNode = ActionNode.textAction(
                "Select all",
                () => this.selectAllOnClick(),
                undefined,
                undefined,
                true,
                undefined,
                true,
            );
            this.selectNoneNode = ActionNode.textAction(
                "Select none",
                () => this.selectNoneOnClick(),
                undefined,
                undefined,
                true,
                undefined,
                true,
            );
            const selectAllNoneClass = params.selectAllAndNoneCustomClass
                ? " " + params.selectAllAndNoneCustomClass
                : "";
            this.selectAllNoneNode = Dom.div(
                { class: "select-all-none" + selectAllNoneClass },
                this.selectAllNode.node,
                " / ",
                this.selectNoneNode.node,
            );
            Dom.place(this.selectAllNoneNode, this.node);
            this.registerDestroyable([this.selectAllNode, this.selectNoneNode]);
        }
    }
    protected override prepRowElement(e: T | string): MultiSelect.CheckboxRow {
        // This mostly mirrors prepRowElement in BaseSelect, with additional logic for the checkbox.
        const checkbox = new Checkbox({
            state: false,
            onUserClick: () => {
                // There's some dojo weirdness that happens by pairing the checkbox
                // with a clickable row, causing the checkbox to unselect itself when it
                // itself is clicked on (rather than the rest of the row)--recheck it
                // on a user click to circumvent this.
                checkbox.toggle(true);
            },
        });
        const inputElem = checkbox.getInputElem();
        Dom.addClass(inputElem, "multiselect__checkbox");
        const elemDisplay = this.shortDisplay(e);
        const elemNode = Dom.span({ class: "label-node" }, elemDisplay);
        return {
            node: Dom.div(
                {
                    class: this.getRowElementCssClass() + " multiselect-row",
                    style: { position: "relative" },
                    ...everClassProp(EVERCLASS.BASE_SELECT.OPTION),
                },
                [Dom.node(checkbox), elemNode],
            ),
            checkbox,
            elemNode,
            elemDisplay,
            onDestroy: [checkbox],
        };
    }
    protected override getMirrorTooltip(row: MultiSelect.CheckboxRow): Tooltip.MirrorTooltip {
        return new Tooltip.FlexibleMirrorTooltip(
            row.elemNode,
            Dom.div(row.elemDisplay),
            row.elemNode,
            this.mirrorTooltipPosition,
        );
    }
    override getSelected() {
        return Arr.flat<T>(this.classOrderStr.map((clazz) => this._selectedMap[clazz]));
    }
    hasTextbox() {
        return true;
    }
    select(elems: T | T[], silent?: boolean, readOnlyOverride?: boolean) {
        Arr.wrap(elems).forEach((e) => {
            this.toggle(e, silent, true, readOnlyOverride);
        });
    }
    selectAll(silent?: boolean) {
        this.forEachElement((e) => {
            this.toggleInner(e.row(), e.element, silent, true);
        });
    }
    toggleAll(toggler: (elem: T) => boolean, silent: boolean) {
        this.forEachElement((e) => {
            this.toggleInner(e.row(), e.element, silent, toggler(e.element));
        });
    }
    unselect(elems: T | T[], silent?: boolean) {
        if (elems) {
            Arr.wrap(elems).forEach((e) => {
                this.toggle(e, silent, false);
            });
        } else {
            this.unselectAll();
        }
    }
    unselectAll(silent?: boolean) {
        this.forEachElement((e) => {
            this.toggleInner(e.row(), e.element, silent, false);
        });
    }
    override setDisabled(disabled = true, disableSelectAllNone = false): void {
        super.setDisabled(disabled);
        if (disableSelectAllNone) {
            this.selectAllNode.setDisabled(disabled);
            this.selectNoneNode.setDisabled(disabled);
            Dom.toggleClass(this.selectAllNoneNode, "disabled");
        }
    }
    protected override addClass(className: string) {
        if (!this._selectedMap) {
            this._selectedMap = {};
        }
        this._selectedMap[className] = [];
        return super.addClass(className);
    }
    protected doKeySelect() {
        this.drawSelected();
    }
    protected override selectInitial() {
        if (this.initialSelected) {
            this.select(this.initialSelected, true, true);
        }
    }
    private setCheckbox(row: MultiSelect.CheckboxRow, selected: boolean) {
        if (row.checkbox && row.checkbox.isSet() !== selected) {
            row.checkbox.toggle();
        }
    }
    override setSelected(row: Dom.Nodeable, selected = true): void {
        super.setSelected(row, selected);
        this.setCheckbox(row as MultiSelect.CheckboxRow, selected);
    }
    // Useful for overriding
    protected _onSelect(elem: T, isNew?: boolean) {
        this.searchElements(elem, (clazz, idx) => {
            this.setCheckbox(clazz.elements[idx].row() as MultiSelect.CheckboxRow, true);
        });
        this.onSelect(elem, isNew, this);
        this.onChange(elem, true, this._selectedMap);
    }
    protected override toggleInner(
        node: Dom.Nodeable,
        elem: T,
        silent: boolean,
        add: boolean | MouseEvent,
        readOnlyOverride?: boolean,
        alsoSetValue?: boolean,
    ): void {
        // This behavior mostly mirrors toggleInner in BaseSelect, except that when an element is
        // selected the checkbox is ticked.
        if (this.readOnly(elem) && !readOnlyOverride) {
            return;
        }
        const isSelected = Dom.hasClass(node, "selected");
        // Is add a click event? If so, determine if we're adding the object.
        if (add !== !!add) {
            add = !isSelected || this.selectOnSame;
        }
        if (add) {
            if (isSelected) {
                if (this.selectOnSame && !silent) {
                    this._onSelect(elem);
                }
            } else {
                this._onToggleInner(elem, true);
                Dom.addClass(node, "selected");
                this.setCheckbox(node as MultiSelect.CheckboxRow, true);
                if (!silent) {
                    this._onSelect(elem);
                }
            }
        } else if (!add && isSelected) {
            this._onToggleInner(elem, false);
            Dom.removeClass(node, "selected");
            this.setCheckbox(node as MultiSelect.CheckboxRow, false);
            if (!silent) {
                this._onUnselect(elem);
            }
        }
    }
    protected _onToggleInner(elem: T, add: boolean) {
        if (add) {
            if (this.unsorted) {
                this._selectedMap[this.getClassName(elem)].push(elem);
            } else {
                Arr.insertSorted(this._selectedMap[this.getClassName(elem)], elem);
            }
        } else {
            if (this.unsorted) {
                Arr.remove(this._selectedMap[this.getClassName(elem)], elem);
            } else {
                Arr.removeSorted(this._selectedMap[this.getClassName(elem)], elem);
            }
        }
    }
    protected _onUnselect(elem: T, isNew?: boolean) {
        this.searchElements(elem, (clazz, idx) => {
            this.setCheckbox(clazz.elements[idx].row() as MultiSelect.CheckboxRow, false);
        });
        this.onUnselect(elem);
        this.onChange(elem, false, this._selectedMap);
    }
}

module MultiSelect {
    // Because multiselects have checkboxes that are set up by prepRowElement(), this method should
    // not be over-written. Multiselects that need to do something special in prepRowElement() need
    // to subclass and override the method, making sure to call super(). Also, dontShowSelectIcon will
    // always be true, so no need to include it in MultiSelect.Params.
    type MultiSelectBaseParams<T extends Base.Object> = Omit<
        BaseSelect.Params<T[], T>,
        "prepRowElement" | "dontShowSelectIcon"
    >;

    export interface Params<T extends Base.Object> extends MultiSelectBaseParams<T> {
        /** Only triggered by the "Select All/None" buttons (enabled with selectAllAndNoneButtons: true) */
        onSelectAll?: () => void;
        onSelectNone?: () => void;
        // Alters _onToggleInner to insert and remove linearly
        unsorted?: boolean;
        /** Show "Select All" and "Select None" buttons? */
        selectAllAndNoneButtons?: boolean;
        // Should clicking "Select All" or "Select None" trigger onChange() for each element?
        silentSelectAllAndNone?: boolean;
        // Custom class for styling
        selectAllAndNoneCustomClass?: string;
    }

    export interface CheckboxRow extends BaseSelect.Row {
        checkbox: Checkbox;
        elemNode: HTMLElement;
        elemDisplay: string;
    }
}

export = MultiSelect;
